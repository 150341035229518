import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faBars,
  faBell,
  faCalendarAlt,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faClock,
  faDownload,
  faEnvelope,
  faEuroSign,
  faExclamationTriangle,
  faMapMarkerAlt,
  faPhone,
  faPlus,
  faTimes,
  faMinus,
  faSearch,
  faSignOut,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faUser,
  faGlobe,
  faTachometerFast,
  faGraduationCap,
  faFileAlt,
  faUsersMedical,
  faHashtag,
  faCheckCircle,
  faTrashAlt,
  faSortUp,
  faSortDown,
  faTrash,
  faPencil,
  faMobile,
  faUpload,
  faUserCheck,
  faBold,
  faItalic,
  faListUl,
  faLink,
  faUnlink,
  faUndoAlt,
  faRedoAlt,
} from '@fortawesome/pro-regular-svg-icons';

config.autoAddCss = false;

library.add(
  faBars,
  faBell,
  faCalendarAlt,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faClock,
  faDownload,
  faEuroSign,
  faEnvelope,
  faExclamationTriangle,
  faMapMarkerAlt,
  faPhone,
  faPlus,
  faTimes,
  faMinus,
  faSearch,
  faSignOut,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faUser,
  faGlobe,
  faTachometerFast,
  faGraduationCap,
  faFileAlt,
  faUsersMedical,
  faHashtag,
  faCheckCircle,
  faTrashAlt,
  faSortUp,
  faSortDown,
  faTrash,
  faPencil,
  faMobile,
  faUpload,
  faUserCheck,
  faBold,
  faItalic,
  faListUl,
  faLink,
  faUnlink,
  faUndoAlt,
  faRedoAlt,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
