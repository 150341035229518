//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppHeader from './partials/AppHeader';
import AppProfileMenu from './partials/AppProfileMenu';

export default {
  components: {
    AppHeader,
    AppProfileMenu,
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      this.profilePanel = false;
    },
  },
};
