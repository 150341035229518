import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import {nl} from '@braid/vue-formulate-i18n';
import yourErrorHandler from '~/libs/error-handler';
import FormulateAutocompleteInput from '~/components/formulate/AutocompleteInput';
import Tiptap from '~/components/formulate/Tiptap';

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      classification: 'text',
      component: FormulateAutocompleteInput,
    },
    tiptap: {
      classification: 'text',
      component: Tiptap,
    },
  },
  plugins: [nl],
  locale: 'nl',
  classes: {
    input(context) {
      switch (context.type) {
        case 'button':
          return 'btn btn-primary';
        case 'radio':
          return 'input-hidden';
        case 'checkbox':
          return 'input-hidden';
        case 'select':
          return 'bg-white text-gray-darker w-full border border-gray-darker focus:shadow-form border-box outline-none p-3 rounded select-arrow';
        default:
          return 'bg-white text-gray-darker w-full border border-gray-darker focus:shadow-form border-box outline-none p-3 rounded';
      }
    },
    element(context) {
      switch (context.type) {
        case 'checkbox':
          return '';
        case 'radio':
          return 'radio';
        default:
          return 'w-full';
      }
    },
    wrapper(context) {
      switch (context.type) {
        case 'radio':
          return 'relative input-flex mb-2';
        case 'checkbox':
          return 'relative flex flex-col mb-2';
        default:
          return 'mb-4';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left';
        default:
          return 'text-secondary font-bold';
      }
    },
    decorator: (context) => {
      let baseBox = 'border rounded border-secondary focus:shadow-form inline-block w-4 h-4 mr-2';
      let baseRadio = 'border rounded-full border-secondary focus:shadow-form inline-block w-4 h-4 mr-2';
      switch (context.type) {
        case 'checkbox':
          if (context.hasValue) {
            baseBox += ' bg-secondary';
          } else {
            baseBox += ' bg-white';
          }
          return baseBox;
        case 'radio':
          if (context.hasValue) {
            baseRadio += ' bg-secondary';
          } else {
            baseRadio += ' bg-white';
          }
          return baseRadio;
        default:
          return '';
      }
    },
    help(context) {
      switch (context.type) {
        case 'file':
          return '-mt-4 mb-4 text-gray-darker';
        default:
          return 'mb-1 text-black';
      }
    },
    error: 'text-negative text-xs mb-4 -mt-3',
  },
  errorHandler: yourErrorHandler,
});
