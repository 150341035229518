// todo: handle graphql errors? also checkout apollo error handler
export default ({response}) => {
  if (response && response.status) {
    switch (response.status) {
      case 422:
        return {
          inputErrors: response.data.errors,
          formErrors: response.data.message,
        };
    }
  } else {
    return {
      inputErrors: {},
      formErrors: ['There was an unknown error occurred.'],
    };
  }
};
