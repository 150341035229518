import Vue from 'vue';

Vue.mixin({
  computed: {
    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 150);
      return date;
    },
    maxBirthDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 10);
      return date;
    },
  },
});
