//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    newsBarOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    newsPreview: 'Controle Zilveren kruis: Onterecht gebruik van de prestatiecode 24005 ‘Behandeling overige natuurgeneeskunde’',
    newsLink: '/nieuws',
  }),
};
