export default function ({app, req}) {
  // GraphQL link handler
  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      // Add authorization token
      const token = app.$cookiz.get('auth._token.local');

      if (token) {
        options.headers.authorization = token;
      }

      if (process.server) {
        options.headers['x-forwarded-for'] = req.connection.remoteAddress || req.socket.remoteAddress;
      }

      return fetch(uri, options);
    },
  };

  return {
    httpLinkOptions: linkOptions,
    batching: true,
    inMemoryCacheOptions: {
      typePolicies: {
        Page: {
          keyFields: ['slug', 'locale'],
        },
      },
    },
  };
}
