import { render, staticRenderFns } from "./profile.vue?vue&type=template&id=ceb1b32a&"
import script from "./profile.vue?vue&type=script&lang=js&"
export * from "./profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CookieStatement: require('/data/www/nvpa.org/nvpa/nuxt/components/CookieStatement.vue').default})
