import {page as pageQuery} from '~/graphql/queries/page.gql';
import {menus as menusQuery} from '~/graphql/queries/menus';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  step_1: null,
  step_2: null,
  step_3: null,
  menuFooter: {},
  footerBottom: [],
  menuMain: [],
});

export const actions = {
  async nuxtServerInit({commit}) {
    const {data: {menus}} = await this.app.apolloProvider.defaultClient.query({
      query: menusQuery,
    });

    commit('SET_MENU_FOOTER', menus.find(m => m.name === 'footermenu')?.items);
    commit('SET_FOOTER_BOTTOM', menus.find(m => m.name === 'footerbottom')?.items);
    commit('SET_MAIN_MENU', menus.find(m => m.name === 'mainmenu')?.items);
  },
  async getPage({commit}, segments = 'home') {
    const {data: {page}} = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      variables: {
        segments: segments === '' ? 'home' : segments,
      },
    });

    if (!page) {
      const e = new Error(`page ${segments} not found`);
      e.statusCode = 404;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_STEP_1(state, payload) {
    state.step_1 = payload;
  },
  SET_STEP_2(state, payload) {
    state.step_2 = payload;
  },
  SET_STEP_3(state, payload) {
    state.step_3 = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_FOOTER_BOTTOM(state, payload) {
    state.footerBottom = payload;
  },
  SET_MAIN_MENU(state, payload) {
    state.menuMain = payload;
  },
};
