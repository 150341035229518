const middleware = {}

middleware['adminOnly'] = require('../middleware/adminOnly.js')
middleware['adminOnly'] = middleware['adminOnly'].default || middleware['adminOnly']

middleware['commissieOnly'] = require('../middleware/commissieOnly.js')
middleware['commissieOnly'] = middleware['commissieOnly'].default || middleware['commissieOnly']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
