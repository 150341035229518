import Vue from 'vue';

Vue.mixin({
  data: () => ({
    downloadingFiles: [],
  }),
  methods: {
    async downloadFile(file) {
      this.downloadingFiles.push(file.id);
      try {
        const url = file.url.includes('https://') ? file.url : `${window.location.origin}${file.url}`;

        const {data} = await this.$axios.get(url, {
          responseType: 'blob',
          headers: {
            Authorization: this.$cookies.get('auth._token.local'),
          },
        });
        if (data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = file.name;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100);
        }
      } catch (e) {
        console.error(e);
        this.$toast.error('Het bestand kon niet gedownload worden.');
      } finally {
        const index = this.downloadingFiles.findIndex(f => f === file.id);
        if (index !== -1) {
          this.downloadingFiles.splice(index, 1);
        }
      }
    },
  },
});
