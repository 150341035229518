//
//
//
//
//
//
//
//
//
//
//

import AppHeader from './partials/AppHeader';
import AppFooter from './partials/AppFooter';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data: () => ({
    mobileMenu: false,
  }),
  methods: {
    async logout() {
      await this.$auth.logout();
      this.profilePanel = false;
    },
  },
};
