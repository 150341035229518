import { render, staticRenderFns } from "./AppProfileMenu.vue?vue&type=template&id=25fd23b7&scoped=true&"
import script from "./AppProfileMenu.vue?vue&type=script&lang=js&"
export * from "./AppProfileMenu.vue?vue&type=script&lang=js&"
import style0 from "./AppProfileMenu.vue?vue&type=style&index=0&id=25fd23b7&lang=SCSS&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fd23b7",
  null
  
)

export default component.exports