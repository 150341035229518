//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      menuFooter: state => state.menuFooter,
      footerBottom: state => state.footerBottom,
    }),
  },
  methods: {
    external(url) {
      const regex = /^((http|https):\/\/)/;
      return regex.test(url);
    },
  },
};
