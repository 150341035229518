//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapState} from 'vuex';

export default {
  data: () => ({
    newsBarOpen: false,
    mobileMenu: false,
    phone: '0413 - 330 680',
    email: 'info@nvpa.org',
    profileMenu: [
      {
        link: {name: 'mijn-profiel-dashboard'},
        title: 'Dashboard',
        icon: ['far', 'tachometer-fast'],
      },
      {
        link: {name: 'mijn-profiel'},
        title: 'Mijn profiel',
        icon: ['far', 'user'],
      },
      {
        link: {name: 'mijn-profiel-scholing'},
        title: 'Scholing',
        icon: ['far', 'graduation-cap'],
      },
      {
        link: {name: 'mijn-profiel-activiteiten'},
        title: 'Activiteiten',
        icon: ['far', 'calendar-alt'],
      },
      {
        link: {name: 'mijn-profiel-documenten'},
        title: 'NVPA documenten',
        icon: ['far', 'file-alt'],
      },
      {
        link: {name: 'mijn-profiel-mijn-documenten'},
        title: 'Mijn documenten',
        icon: ['far', 'file-alt'],
      },
      {
        link: {name: 'mijn-profiel-vakgroep'},
        title: 'Mijn vakgroep',
        icon: ['far', 'users-medical'],
      },
      {
        link: {name: 'mijn-profiel-promotiemateriaal'},
        title: 'Promotiemateriaal',
        icon: ['far', 'hashtag'],
      },
    ],
  }),
  computed: {
    ...mapState({
      menuMain: state => state.menuMain,
    }),
  },
  watch: {
    $route() {
      this.mobileMenu = false;
    },
  },
};
